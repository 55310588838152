@import "../../../../shared/styles/mixins/image";
@import "../../../../shared/styles/variables/media";

.blog {
    position: relative;

    width: 290px;
    height: 400px;

    @media (max-width: $media-md) {
        width: 224px;
        height: 320px;
    }

    &_wide {
        max-width: 335px;
        width: 100%;

        height: 385px;
    }

    &__poster {
        @include img-full;

        border-radius: var(--border-radius-card-left) var(--border-radius-m) var(--border-radius-m)
            var(--border-radius-m);
    }

    &__container {
        position: relative;
        z-index: var(--z-index-mask-higher);

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        height: 100%;

        &_hiring {
            border-radius: var(--border-radius-card-left) var(--border-radius-m) var(--border-radius-m)
                var(--border-radius-m);

            background-color: var(--color-career-red);
        }
    }

    &__title {
        font: var(--font-xxl);
        color: var(--color-primary-white);
        padding-inline: 30px;
        margin-bottom: 50px;

        @media (max-width: $media-md) {
            padding-inline: 24px;
            margin-bottom: 25px;
        }
    }

    &__description {
        margin-bottom: 40px;
        padding-inline: 30px;

        color: var(--color-primary-white);
        font: var(--font-xsl);

        @media (max-width: $media-md) {
            margin-bottom: 24px;
            padding-inline: 24px;
        }

        &_hiring {
            font: var(--font-l);
        }
    }
}
