.container {
    max-width: var(--size-container);
    margin-inline: auto;

    &__content {
        position: relative;
    }
}

.standard {
    padding-inline: var(--size-padding-container);
}

.large {
    padding-inline: var(--size-padding-container-x2);
}
