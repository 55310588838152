.wrapper {
    box-sizing: border-box;
    background: linear-gradient(90deg, rgb(41, 112, 157), rgb(18, 55, 78));
    font-family: var(--font-family-candidates);
}

.section {
    background-color: #ffffff;
    padding-top: 72px;
    padding-bottom: 72px;
    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 10px;
    }
}

.content {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.2s;
    img {
        width: 100%;
        pointer-events: none;
    }
    @media (max-width: 1280px) {
        width: calc(100% - 120px);
    }
    @media (max-width: 767px) {
        width: calc(100% - 32px);
    }
}

.loader {
    &_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
