@import "../../shared/styles/mixins/gridState";
@import "../../shared/styles/variables/media";

.companyFacts {
    margin-bottom: var(--size-between-section);

    &__title {
        margin-bottom: 50px;
    }
}

@include gridStats;

.block {
    & > p {
        font-weight: 500;
    }

    @media (max-width: $media-xl) {
        & > p {
            font: var(--font-l);
            font-weight: 500;
        }
    }
    @media (max-width: $media-lg) {
        & > p {
            font: var(--font-xl);
            font-weight: 500;
        }
    }
    &_first {
        & p {
            text-align: start;
            @media (max-width: $media-lg) {
                text-align: end;
            }
        }
    }
    &_second {
        align-items: flex-end;

        & > p {
            max-width: 265px;
        }

        @media (max-width: $media-lg) {
            align-items: flex-start;

            & > p {
                align-self: flex-end;
                max-width: initial;
            }
        }
    }

    &_third {
        position: relative;
        grid-area: 1 / 6 / 6 / 7;

        & > svg {
            position: absolute;
            left: 34%;
            top: 24%;
        }

        @media (max-width: $media-lg) {
            align-items: flex-start;
            overflow: hidden;

            & > svg {
                position: absolute;
                left: initial;
                right: -60px;
                top: -90px;
            }
        }
    }

    &_fourth {
        position: relative;

        & > p {
            max-width: 230px;
        }

        @media (max-width: $media-lg) {
            & > p {
                align-self: flex-start;
                text-align: start;

                max-width: 165px;
            }
        }
    }

    &__img {
        position: absolute;
        bottom: 0;
        left: 150px;

        @media (max-width: 1440px) {
            display: none;
        }

        @media (max-width: $media-lg) {
            display: block;
            overflow: hidden;
            left: initial;
            right: 0;

            height: 230px;

            border-bottom-right-radius: var(--border-radius-m);

            & > img {
                height: 230px;

                margin: 0px -110px 0px 0px;
            }
        }
    }
}
