@import "../../shared/styles/variables/media";

.ourOffices {
    --size: 30px;

    margin-bottom: var(--size-between-section);

    &__text {
        font: var(--font-l);
        margin-bottom: var(--size);

        @media (max-width: $media-md) {
            font: var(--font-xl);
        }
    }

    &__countryWrapper {
        display: flex;
        flex-direction: column;
        gap: var(--size);
    }

    &__country {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: var(--size);
    }

    &__count {
        color: var(--color-career-hidden);
    }

    &__wrapperTitle {
        display: flex;
        flex-direction: column;
        gap: var(--size);
        max-width: 320px;

        @media (max-width: $media-md) {
            max-width: initial;
        }
    }

    & ul {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(13, 1fr);
        grid-column-gap: 60px;
        grid-row-gap: 20px;

        overflow-x: auto;

        padding-top: var(--size);
        margin-bottom: 10px;

        @media (max-width: $media-md) {
            grid-template-columns: repeat(4, 130px);

            grid-column-gap: 30px;
            grid-row-gap: 14px;
        }
    }
}
