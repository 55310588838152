.copyToClipboard {
    position: relative;

    cursor: pointer;
}

.tooltip {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;

    transform: translateX(-50%);

    background-color: var(--color-primary-white);
    padding: 10px 20px;
    border-radius: var(--border-radius-s);
    box-shadow: var(--shadow-modal);
}
