@import "../../shared/styles/mixins/image";
@import "../../shared/styles/variables/media";

.worldCompany {
    margin-bottom: var(--size-between-section);
    background-color: var(--color-primary-blue);
    &__wrapper {
        display: flex;
        gap: 150px;
        margin-bottom: 100px;

        @media (max-width: $media-lg) {
            gap: 40px;
        }

        @media (max-width: $media-md) {
            display: block;
            margin-bottom: 50px;
        }
    }

    &__title {
        padding: var(--size-padding-container) var(--size-padding-container) var(--size-padding-container)
            var(--size-padding-container-x2);
        max-width: 660px;

        @media (max-width: $media-md) {
            padding-top: 0;
        }
    }

    &__descriptionWrapper {
        align-self: flex-end;

        max-width: 380px;
        margin-right: var(--size-padding-container);

        @media (max-width: $media-md) {
            max-width: initial;

            padding-top: 40px;
            padding-left: var(--size-padding-container);
        }
    }

    &__description {
        font: var(--font-l);
        color: var(--color-primary-white);
    }
}

.carousel {
    position: relative;

    &__navigation {
        @media (max-width: $media-md) {
            padding-inline: var(--size-padding-container);
            padding-bottom: 20px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        & > svg {
            width: 350px;
            min-height: 82px;
        }

        @media (max-width: $media-md) {
            width: 100%;
            gap: 10px;
        }
    }

    &__desc {
        max-width: 280px;

        text-align: center;
        font-weight: 400;
        color: var(--color-primary-white);
    }
}
