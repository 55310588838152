@import "../../shared/styles/mixins/gridState";

.companyFacts {
    margin-bottom: var(--size-between-section);

    &__title {
        margin-bottom: 50px;
    }
}

@include gridStats;
