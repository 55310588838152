@import "../variables/media";

@mixin textFieldBase {
    padding: 19px 34px;
    border-radius: var(--border-radius-m);
    border: 1px solid var(--color-secondary-grey-2);
    background: var(--color-primary-white);

    font: var(--font-m);
    font-weight: 500;

    transition: border 0.1s ease-in-out;

    width: 100%;

    @media (max-width: $media-md) {
        padding: 16px 34px;
    }

    &_error {
        border-color: var(--secondary-palette-red);
    }

    &:hover,
    &:focus {
        border: 1px solid var(--color-primary-dark-blue);

        transition: border 0.1s ease-in-out;
    }
}
