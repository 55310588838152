.buttonCard {
    padding-top: 20px;
    padding-left: 20px;
    align-self: flex-end;

    &_bottom {
        width: 100%;

        padding-top: 10px;
        padding-left: 0;

        & a {
            width: 100%;
        }
    }
}
