@import "../../shared/styles/variables/media";

.sectionAwards {
    margin-bottom: 230px;

    @media (max-width: $media-md) {
        margin-bottom: 100px;
    }

    &__backgroundWrapper {
        position: relative;
        height: fit-content;

        @media (max-width: $media-md) {
            margin-bottom: 140px;
        }
    }

    &__background {
        position: absolute;
        bottom: 0;
        z-index: var(--z-index-backgound);

        width: 100%;

        object-fit: cover;

        border-bottom-left-radius: var(--border-radius-banner);
        border-bottom-right-radius: var(--border-radius-banner);
    }

    &__trophy {
        position: absolute;
        bottom: -150px;
        left: 0;
        z-index: var(--z-index-backgound);

        width: 580px;

        @media (max-width: $media-lg) {
            width: 470px;
            left: -40px;
        }

        @media (max-width: $media-md) {
            width: 300px;
            left: -40px;
            bottom: -120px;
        }
    }

    &__text {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        margin-bottom: var(--size-between-section);

        @media (max-width: $media-md) {
            flex-direction: column;
        }
    }

    &__desc {
        align-self: flex-end;
        font: var(--font-l);
        width: 50%;
        margin-bottom: 7px;
        @media (max-width: $media-md) {
            width: 100%;
            align-self: flex-start;
            margin-bottom: 0;
        }
    }

    &__listWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 260px;

        @media (max-width: $media-md) {
            margin-bottom: 200px;
        }
    }

    &__list {
        max-width: 800px;
        width: 100%;

        & > li {
            border-bottom: 1px solid var(--color-career-text);
            & > li:first-child {
                border-top: 1px solid var(--color-career-text);
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 80px;

        height: 190px;

        & > p {
            font: var(--font-l);
        }

        & > svg {
            flex-shrink: 0;
            margin-right: 110px;

            transition: transform 0.2s ease-in-out;
        }

        &:hover svg {
            transform: translateX(24px);
            transition: transform 0.2s ease-in-out;
        }

        @media (hover: none) {
            &:hover svg {
                transform: translateX(0);
            }
        }

        @media (max-width: $media-md) {
            height: 130px;

            & > svg {
                margin-right: 0;
            }
        }
    }

    &__buttonWrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__buttonBorder {
        padding-left: 20px;
        padding-top: 20px;
    }

    &__button {
        @media (max-width: $media-md) {
            width: 100%;
        }
    }
}
