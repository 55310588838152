@import "../../../../shared/styles/variables/media";

.jobList {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.grid {
    display: grid;
    grid-template-columns: 3fr 2fr repeat(2, 1.2fr);
    grid-auto-rows: auto;
    grid-column-gap: 80px;
    justify-content: stretch;

    min-width: 60px;
    padding-bottom: 14px;

    color: var(--color-career-text);
    font: var(--font-m);
    font-weight: 400;

    @media (max-width: $media-md) {
        display: none;
    }
}
