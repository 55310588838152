.buttonIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--color-border);
    border-radius: var(--radius-default);
    color: var(--color-black);

    cursor: pointer;

    &_minus,
    &_plus {
        width: 52px;
        height: 52px;

        & > svg {
            width: 26px;
            height: 26px;
        }
    }

    &_delete {
        width: 18px;
        height: 18px;
        border-color: #747989;
        background-color: var(--color-white);

        & > svg {
            width: 20px;
            height: 20px;
        }
    }
}
