.main {
    position: relative;
    z-index: var(--z-index-init);

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    & > header,
    footer {
        flex-shrink: 0;
    }

    & > main {
        flex-grow: 1;
    }

    &_standard {
        background-color: var(--color-background);
        color: var(--color-primary-dark-blue);
    }

    &_gray {
        background-color: var(--color-career-CTA);
    }
}
