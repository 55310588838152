.teamList {
    margin-bottom: var(--size-between-section);

    &__text {
        font: var(--font-l);
        margin-bottom: 30px;

        & > span {
            font-weight: 500;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__noContent {
        text-align: center;
        font: var(--font-l);
        margin-bottom: 200px;
    }
}

.skeleton {
    max-width: 550px;
    height: 28px;
    margin-bottom: 30px;
}
