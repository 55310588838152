@import "../../shared/styles/variables/media";

.earlyCareerMain {
    margin-top: 20px;

    border-top-right-radius: var(--border-radius-banner);
    background-color: var(--color-career-CTA);

    &__wrapper {
        display: flex;
        justify-content: space-between;

        margin-bottom: 75px;

        @media (max-width: $media-md) {
            display: block;
            margin-bottom: 30px;
        }
    }

    &__title {
        max-width: 800px;

        padding: var(--size-padding-container) var(--size-padding-container) var(--size-padding-container)
            var(--size-padding-container-x2);

        @media (max-width: $media-sm) {
            max-width: 310px;
            padding: 0 10px 10px var(--size-padding-container);
        }
    }

    &__description {
        align-self: flex-end;
        max-width: 600px;
        padding: var(--size-padding-container) var(--size-padding-container-x2) var(--size-padding-container)
            var(--size-padding-container);

        font: var(--font-l);

        @media (max-width: $media-md) {
            padding-top: 40px;
        }
    }
}
