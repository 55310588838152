@import "../../shared/styles/variables/media";

.aboutRecruiting {
    padding-top: 60px;
    margin-bottom: 80px;

    @media (max-width: $media-md) {
        padding-top: 20px;
        margin-bottom: 40px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;
        align-items: flex-end;

        padding-top: 30px;
    }

    &__title {
        max-width: 692px;
    }

    &__subtitle {
        max-width: 420px;
        font: var(--font-l);

        @media (max-width: $media-md) {
            max-width: initial;
        }
    }
}
