@import "../../../../shared/styles/variables/media";

.job {
    --widthCard: 380px;
    --heightCard: 408px;

    position: relative;

    width: var(--widthCard);
    height: var(--heightCard);

    border-top-left-radius: var(--border-radius-card-left);
    border-top-right-radius: var(--border-radius-m);
    border-bottom-left-radius: var(--border-radius-m);
    background-color: var(--color-primary-gray);
    @media (max-width: $media-sm) {
        --widthCard: 280px;
        --heightCard: 300px;
    }

    &__container {
        padding-top: 40px;
        padding-inline: 50px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: $media-sm) {
            padding-top: 22px;
            padding-inline: 32px;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;

        height: 90px;

        font: var(--font-xxl);
        font-weight: 500;
        &_hyphens {
            hyphens: auto;
        }
    }

    &__description {
        margin-right: 50px;
        margin-bottom: 32px;

        font: var(--font-l);

        @media (max-width: $media-sm) {
            margin-right: 80px;
        }
    }

    &__wrapperButton {
        position: absolute;
        bottom: 0;
        right: 0;

        padding-top: 20px;
        padding-left: 20px;

        @media (max-width: $media-sm) {
            padding-top: 10px;
            padding-left: 10px;
        }
    }

    &__skeleton {
        width: var(--widthCard);
        height: var(--heightCard);
    }

    &:hover {
        background-color: var(--color-primary-blue);
        color: var(--color-primary-white);
        & svg:last-child {
            fill: var(--color-primary-blue);
            & path {
                stroke: var(--color-primary-cyan);
            }
            & rect {
                stroke: var(--color-primary-blue);
            }
        }
    }
}
