.stageItem {
    position: relative;
    transition: padding 0.2s;
    .or-stage-item__link,
    .or-stage-item__link:active,
    .or-stage-item__link:focus {
        color: inherit;
    }
    .or-stage-item__link:hover {
        text-decoration: none;
    }
    padding-top: calc(168px - 20px);
    @media (max-width: 1280px) {
        padding-top: calc(110px - 20px);
    }
    &_count {
        position: absolute;
        top: 0;
        left: 0;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        font-size: 28px;
        line-height: 64px;
        color: #2a2a2a;
        text-align: center;
        background-color: rgba(43, 42, 84, 0.05);
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #006def;
        }
        &__left {
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            overflow: hidden;
            position: absolute;
            transform: rotate(180deg);
            z-index: 1;
            border-radius: 50%;
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 100%;
                background: #d5dce6;
            }
        }
        &__right {
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            overflow: hidden;
            position: absolute;
            z-index: 1;
            border-radius: 50%;
            transform: rotate(0deg);
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 100%;
                background: #d5dce6;
            }
        }
        &__first {
            transform: rotate(270deg);
        }
        &__second {
            transform: rotate(0deg);
        }
        &__thirdLeft {
            transform: rotate(0deg);
        }
        &__thirdRight {
            transform: rotate(-90deg);
            &:before {
                background: #006def;
            }
        }

        &__fourth {
            transform: rotate(180deg);
            &:before {
                background: #006def;
            }
        }

        &__text {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            position: absolute;
            top: 2px;
            left: 2px;
            border-radius: 50%;
            z-index: 2;
            background-color: #f4f7fa;
        }
        &__title {
            font-size: 24px;
            line-height: 1.125;
            padding-bottom: 12px;
        }
    }
}
