.footer {
    font-weight: normal;
    color: #ffffff;
    opacity: 0.5;
    font-size: 14px;
    line-height: 1.15;
    padding: 48px 0 85px;
    @media (max-width: 1023px) {
        padding: 1px 0 40px;
    }
}

.content {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.2s;
    img {
        width: 100%;
        pointer-events: none;
    }
    @media (max-width: 1280px) {
        width: calc(100% - 120px);
    }
    @media (max-width: 767px) {
        width: calc(100% - 32px);
    }
}
