@import "../../../../shared/styles/variables/media";

.queryDirectionVacancy {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__selectList {
        display: flex;
        gap: 20px;

        @media (max-width: $media-lg) {
            flex-direction: column;
        }
    }

    &__cheackboxList {
        display: flex;
        gap: 30px;

        @media (max-width: $media-lg) {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__cheackboxHybrid {
        display: flex;
        gap: 2px;
    }

    &__tooltip {
        width: max-content;
        max-width: 200px;
    }
}
