.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: var(--zIndex-modal);

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0 0 0 / 50%);
}

.content {
    padding: var(--size-space-m);
    border-radius: var(--border-radius-m);
    background-color: var(--bg-color);
    max-width: 800px;
    position: relative;
}
