.wrapper {
    position: relative;

    width: 100%;
}

.error {
    position: absolute;
    left: 0;
    top: calc(100% + 4px);

    width: max-content;

    font: var(--font-s);
    color: var(--secondary-palette-red);
}
