@import "../../../shared/styles/variables/media";

.header {
    height: 80px;

    &__wrapper {
        background: white;
    }
}

.content {
    max-width: 1285px;
    margin-inline: auto;
    position: relative;
    z-index: 1;
}

.link {
    cursor: pointer;
}

.directionMain {
    position: relative;

    padding-top: 40px;
    margin-bottom: var(--size-between-section);

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        background-color: var(--color-career-light-blue);
        border-radius: var(--border-radius-background);

        padding-bottom: 120px;

        @media (max-width: $media-lg) {
            flex-direction: column;
            align-items: center;
            padding: 40px 20px;
            margin-bottom: 20px;
        }
    }

    &__textWrapper {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 100px;

        max-width: 650px;

        @media (max-width: $media-lg) {
            max-width: initial;
        }
    }

    &__title {
        padding: 45px 45px 45px 25px;

        @media (max-width: $media-lg) {
            padding: 0;
            padding-inline: var(--size-padding-container);
            margin-bottom: 40px;
        }
    }

    &__descriptionWrapper {
        padding-left: var(--size-padding-container);
        font: var(--font-l);

        @media (max-width: $media-lg) {
            padding-inline: var(--size-padding-container);
        }
    }

    &__imageWrapper {
        max-width: 530px;
        height: 470px;

        margin-top: var(--size-padding-container);
        margin-right: var(--size-padding-container);

        @media (max-width: $media-lg) {
            text-align: center;
            max-width: initial;
            margin: 0;
            width: 100%;
            max-height: 530px;
        }

        @media (max-width: $media-sm) {
            max-height: 350px;
        }
    }

    &__image {
        max-width: 100%;
        height: 100%;

        object-fit: cover;

        border-radius: var(--border-radius-banner);
    }

    &__subscribeWrapper {
        position: absolute;
        right: 320px;
        bottom: 0;

        padding-top: 20px;
        padding-inline: 20px;
    }

    &__subscribe {
        @media (max-width: $media-lg) {
            display: flex;
            justify-content: center;
        }
    }
}
