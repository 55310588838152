.button {
    padding: 15px 40px;

    border-radius: var(--radius-default);
    border: 1px solid transparent;

    font-weight: var(--weight-medium);
    line-height: 18px;
    cursor: pointer;

    transition: var(--transition-default);

    &_circle {
        border-radius: var(--radius-circle);
    }

    &_primary {
        background-color: var(--color-blue);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-white);
            color: var(--color-blue);
            border-color: var(--color-blue);
        }
    }

    &_other {
        color: var(--color-lightBlue);
    }

    &:hover {
        transition: var(--transition-default);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: var(--opacity-minimal);
    }
}
