.navDown {
    position: absolute;
    right: var(--size-padding-container-x2);
    bottom: 0;

    display: flex;
    gap: 10px;

    padding-inline: 20px;
    padding-top: 20px;
}

.navOnSides {
    position: absolute;
    top: 50%;
    z-index: var(--z-index-mask);

    transform: translateY(-50%);

    &_left {
        left: 0;
    }

    &_right {
        right: 0;
    }
}

.navOnBothSides {
    position: absolute;
    top: 50%;
    z-index: var(--z-index-mask);

    transform: translateY(-50%);

    &_left {
        left: -70px;
    }

    &_right {
        right: -70px;
    }
}

.navOnSidesDown {
    display: flex;
    justify-content: space-between;

    padding-top: 40px;

    &_left {
        left: 0;
    }

    &_right {
        right: 0;
    }
}
