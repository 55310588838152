@import "../../shared/styles/variables/media";

.notFound {
    padding: 20% 0;

    &__wrapper {
        margin-inline: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__icon {
        margin-bottom: 46px;
    }
    &__header {
        font: var(--font-xsl);
        text-align: center;

        max-width: 390px;
        margin-bottom: 40px;
    }
    &__control {
        display: flex;
        column-gap: 40px;
        row-gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    &__button {
        @media (max-width: $media-md) {
            width: 100%;
        }
    }
}
