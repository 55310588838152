@import "../../../../../shared/styles/variables/media";

.step {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    width: 380px;
    height: 240px;

    background-color: var(--color-primary-blue);
    border-radius: var(--border-radius-card-left) var(--border-radius-m) var(--border-radius-m) var(--border-radius-m);
    color: var(--color-primary-white);
    padding: 35px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: background-color 0.1s ease-in-out;

    @media (max-width: $media-md) {
        width: 302px;
        height: 203px;
    }

    &_active {
        background-color: var(--color-primary-white);
        color: var(--color-primary-dark-blue);
    }

    &__number {
        font: var(--font-xl);
    }

    &__title {
        max-width: 300px;
        min-height: 90px;

        font: var(--font-xxl);
        font-weight: 500;

        @media (max-width: $media-md) {
            min-height: 70px;
        }
    }

    &:hover {
        background-color: var(--color-primary-white);
        color: var(--color-primary-dark-blue);
        transition: background-color 0.1s ease-in-out;
    }
}
