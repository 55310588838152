@import "../../../../shared/styles/variables/media";

.wrapper {
    display: none;

    @media (max-width: $media-lg) {
        display: block;
    }
}

.burger {
    position: relative;
    z-index: var(--z-index-all);

    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 40px;

    & > p {
        width: 100%;
        height: 1px;
        background-color: var(--color-primary-dark-blue);

        transition: all 0.2s ease-in-out;
    }

    &_active {
        & > p {
            background-color: var(--color-primary-white);
        }
        & > p:last-child {
            transform: rotate(-45deg) translateX(8px);
        }
        & > p:first-child {
            transform: rotate(45deg) translateX(8px);
        }
    }
}

.menu {
    display: none;
    flex-direction: column;
    gap: 60px;

    position: fixed;
    z-index: var(--z-index-modal);
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    overflow-x: auto;

    padding-top: 100px;
    padding-inline: var(--size-padding-container);

    background-color: var(--color-primary-dark-blue);

    @media (max-width: $media-lg) {
        display: flex;
    }

    &__nav {
        display: flex;
        flex-direction: column;
        gap: 40px;

        color: var(--color-primary-white);
    }

    &__directionsList {
        display: flex;
        flex-direction: column;
        gap: 20px;

        padding-top: 20px;
    }

    &__arrowDown {
        & > path {
            stroke: var(--color-primary-white);
        }
    }

    &__buttom {
        width: 100%;
        margin-bottom: 120px;
    }
}
