@import "../../../../../shared/styles/variables/media";

.button {
    cursor: pointer;
    color: var(--color-primary-blue);

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &_button {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 12px 12px 12px 30px;

            @media (max-width: $media-md) {
                padding: 9px 12px 9px 30px;
            }
        }
    }

    &__text {
        font: var(--font-xl);

        &_button {
            font: var(--font-m);
            font-weight: 500;
            line-height: 18px;
            color: var(--color-primary-dark-blue);
        }
    }

    &__icons {
        display: flex;
        align-items: center;
    }

    &__vector {
        transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;

        &_open {
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;
        }
    }

    &__close {
        display: none;

        &_button {
            display: block;
        }
    }

    &_button {
        display: block;
        width: 100%;

        border-radius: var(--border-radius-m);
        border: 1px solid var(--color-primary-dark-blue);
        background: var(--color-primary-white);

        &:hover,
        &:focus {
            border-color: var(--color-secondary-grey-2);
        }
    }

    &__loadingSvg {
        animation: spin 1s linear infinite;
    }

    &:disabled,
    button[disabled] {
        border-color: var(--color-career-text);
        color: var(--color-career-text);

        & path {
            stroke: var(--color-career-text);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
