@import "../../../../shared/styles/variables/media";

.container {
    &_green {
        background-color: var(--color-career-CTA);
    }

    &_standard {
        background-color: var(--color-primary-white);
    }
}

.footer {
    display: flex;
    flex-direction: column;
    row-gap: 50px;

    border-top-right-radius: var(--border-radius-m);
    border-top-left-radius: var(--border-radius-m);

    background-color: var(--color-primary-black);
    color: var(--color-primary-white);
    padding: 30px var(--size-padding-container);

    &__link {
        &:hover {
            color: var(--color-secondary-grey-2);
        }

        @media (hover: none) {
            &:hover {
                color: var(--color-primary-white);
            }
        }
    }

    //первая секция футтера
    &__firstSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        transform: translateX(-20px);
    }

    &__button {
        @media (max-width: $media-lg) {
            display: none;
        }
    }

    //вторая секция футтера
    &__navigationCareer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 50px;
    }

    &__careerLinks {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        gap: 50px;

        @media (max-width: $media-lg) {
            flex-direction: column;
            gap: 40px;

            width: 100%;
        }

        & > ul {
            display: flex;
            flex-direction: column;

            gap: 16px;
        }
    }

    /*третья секция футтера*/
    &__navigationProject {
        display: flex;
        flex-wrap: wrap;
        column-gap: 60px;
        row-gap: 20px;

        @media (max-width: $media-lg) {
            flex-direction: column;
        }

        & > ul {
            display: flex;
            flex-wrap: wrap;
            column-gap: 60px;
            row-gap: 20px;

            @media (max-width: $media-lg) {
                flex-direction: column;
            }
        }
    }

    /*четвёртая секция футтера*/
    &__disclaimer {
        font: var(--font-s);
        color: var(--color-career-text);
    }

    /*пятая секция футтера*/
    &__copyrights {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;

        font: var(--font-s);
        color: var(--color-career-text);
    }
}
