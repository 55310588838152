.accordion {
    display: flex;
    flex-direction: column;

    width: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__vector {
        display: flex;
        align-items: center;
        justify-content: center;

        transform: rotate(180deg);
        transition: transform 0.2s ease-in-out;

        &_open {
            transform: rotate(0deg);
            transition: transform 0.2s ease-in-out;
        }
    }
}
