.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    opacity: 0;
    pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;

    &_open {
        pointer-events: auto;
        opacity: 1;
        z-index: var(--z-index-modal);
        background-color: rgba($color: #ffffff, $alpha: 0.8);
    }
    &__content {
        border-radius: var(--border-radius-s);
        border: 1px solid var(--color-career-text);
        background: var(--color-background);

        margin-inline: var(--size-between-section);

        max-width: 710px;
    }
}
