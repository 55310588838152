.acceptForm {
    padding-top: 14%;
    padding-bottom: 14%;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;

        margin-inline: auto;

        font: var(--font-l);
        text-align: center;

        max-width: 300px;
    }
}
