.carousel {
    &__container {
        position: relative;
    }

    &__down {
        padding-bottom: 134px;
    }

    :global(.swiper-pagination) {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        padding-top: 50px;
        position: initial;

        overflow: auto;
    }

    :global(.swiper-pagination-bullet) {
        all: unset;

        font: var(--font-m);
        color: var(--color-primary-dark-blue);
        font-weight: 500;

        cursor: pointer;
    }

    :global(.swiper-pagination-bullet-active) {
        color: var(--color-primary-blue);
    }
}
