@import "../../../../shared/styles/variables/media";

.vacancy {
    display: grid;
    grid-template-columns: 3fr 2fr repeat(2, 1.2fr);
    grid-auto-rows: auto;
    grid-column-gap: 80px;
    justify-content: stretch;

    min-width: 60px;
    padding-bottom: 14px;

    color: var(--color-career-text);
    font: var(--font-m);
    font-weight: 400;

    border-bottom: 1px solid var(--color-secondary-grey);

    @media (max-width: $media-xl) {
        grid-column-gap: 20px;
    }

    @media (max-width: $media-md) {
        display: flex;
        flex-direction: column;

        padding-bottom: 20px;
    }

    &:hover {
        .direction {
            color: var(--color-primary-blue);

            transition: color 0.2s ease-in-out;
        }
    }
}

.direction {
    font: var(--font-l);
    color: var(--color-primary-dark-blue);

    max-width: 340px;
    min-height: 60px;

    transition: color 0.2s ease-in-out;

    @media (max-width: $media-md) {
        font-size: 24px;
        margin-bottom: 20px;
    }
}

.teams {
    color: var(--color-primary-dark-blue);
    @media (max-width: $media-md) {
        margin-bottom: 20px;
    }
}

.country {
    @media (max-width: $media-md) {
        margin-bottom: 10px;
    }
}

.skeleton {
    & > * {
        display: flex;
        flex-direction: column;
        gap: 10px;

        height: 60px;

        & > * {
            height: 20px;
        }

        & > :last-child {
            width: 60%;
        }
    }
}
