@import "../../../shared/styles/variables/media";

:root {
    /* fonts */
    --font-family: "SuisseIntl", Arial;
    --font-family-candidates: "SuisseIntl", Arial;

    --font-size-s: 12px;
    --font-line-s: 14px;
    --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family);

    --font-size-m: 16px;
    --font-line-m: 24px;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family);

    --font-size-l: 22px;
    --font-line-l: 28px;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family);

    --font-size-xsl: 26px;
    --font-line-xsl: 36px;
    --font-xsl: var(--font-size-xsl) / var(--font-line-xsl) var(--font-family);

    --font-size-xl: 32px;
    --font-line-xl: 36px;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family);

    --font-size-xxl: 40px;
    --font-line-xxl: 44px;
    --font-xxl: var(--font-size-xxl) / var(--font-line-xxl) var(--font-family);

    --font-size-title-s: 62px;
    --font-line-title-s: 62px;
    --font-title-s: var(--font-size-title-s) / var(--font-line-title-s) var(--font-family);

    --font-size-title: 88px;
    --font-line-title: 88px;
    --font-title: var(--font-size-title) / var(--font-line-title) var(--font-family);

    --font-size-button: 18px;
    --font-line-button: 24px;
    --font-button: var(--font-size-button) / var(--font-line-button) var(--font-family);

    /* color */
    --color-background: #ffffff;

    --color-primary-black: #000000;
    --color-primary-gray: #d8e3ed;
    --color-primary-blue: #1b00e5;
    --color-primary-dark-blue: #001a3d;
    --color-primary-white: #ffffff;
    --color-primary-cyan: #01ffe0;
    --secondary-palette-red: #ff5351;

    --color-secondary-grey: #c5d7e6;
    --color-secondary-grey-2: #97a7bd;
    --secondary-secondary-grey-3: #465375;
    --color-secondary-dark-blue: #0053b8;

    --color-career-text: #839ec0;
    --color-career-light-blue: #e8f3fc;
    --color-career-hidden: #c5d6eb;
    --color-career-CTA: #d8e3ed;

    --color-career-red: #e8a495;
    --color-career-galaxy-purple: #601feb;
    --color-career-green: #bcddcb;
    --color-career-purple: #acafd7;
    --color-career-blue: #b2d9f1;
    --color-career-yellow: #fbfcd6;

    /* size */
    --size-container: 1400px;
    --size-padding-container: 58px;
    --size-padding-container-x2: calc(var(--size-padding-container) * 2);

    --size-between-section: 120px;

    /* shadows */
    --shadow-modal: 0 0 20px 0 rgba(0, 0, 0, 0.15);

    /* z-index */
    --z-index-all: 5;
    --z-index-modal: 4;
    --z-index-select: 3;
    --z-index-mask-higher: 2;
    --z-index-mask: 1;
    --z-index-init: 0;
    --z-index-backgound: -1;

    /* border-radius */
    --border-radius-s: 15px;
    --border-radius-m: 20px;
    --border-radius-banner: 32px;
    --border-radius-background: 50px;
    --border-radius-card-left: 50px;
}

@media (max-width: $media-xl) {
    :root {
        --font-size-infographics: 64px;
        --font-line-infographics: 64px;
        --font-infographics: var(--font-size-infographics) / var(--font-line-infographics) var(--font-family);
    }
}

@media (max-width: $media-lg) {
    :root {
        --font-size-title-s: 40px;
        --font-line-title-s: 44px;
        --font-title-s: var(--font-size-title-s) / var(--font-line-title-s) var(--font-family);

        --font-size-title: 60px;
        --font-line-title: 64px;
        --font-title: var(--font-size-title) / var(--font-line-title) var(--font-family);

        --border-radius-banner: 20px;
    }
}

@media (max-width: $media-md) {
    :root {
        --size-padding-container: 20px;
        --size-padding-container-x2: 20px;

        --size-between-section: 80px;

        --border-radius-banner: 20px;
        --border-radius-background: 20px;
    }
}

@media (max-width: $media-sm) {
    :root {
        --font-size-l: 16px;
        --font-line-l: 24px;
        --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family);

        --font-size-xl: 24px;
        --font-line-xl: 28px;
        --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family);

        --font-size-xsl: 24px;
        --font-line-xsl: 32px;
        --font-xsl: var(--font-size-xsl) / var(--font-line-xsl) var(--font-family);

        --font-size-xxl: 30px;
        --font-line-xxl: 34px;
        --font-xxl: var(--font-size-xxl) / var(--font-line-xxl) var(--font-family);

        --font-size-title: 40px;
        --font-line-title: 44px;
        --font-title: var(--font-size-title) / var(--font-line-title) var(--font-family);

        --font-size-button: 16px;
        --font-line-button: 24px;
        --font-button: var(--font-size-button) / var(--font-line-button) var(--font-family);
    }
}
