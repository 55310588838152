.socialList {
    & > p {
        margin-bottom: 16px;
    }

    & > ul {
        display: flex;
        column-gap: 10px;

        & svg {
            opacity: 1;

            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 0.6;

                transition: opacity 0.2s ease-in-out;
            }

            @media (hover: none) {
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
