.title {
    display: inline-block;

    font-size: 100%;

    &_1 {
        font: var(--font-title);
        font-weight: 500;
    }

    &_2 {
        font: var(--font-title);
        font-weight: 500;
    }

    &_3 {
        font: var(--font-title-s);
        font-weight: 500;
    }
}
