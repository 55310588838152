@import "../../../shared/styles/variables/media";

.newsletter {
    width: 100%;
    height: 100%;

    padding: 54px 60px 24px;

    @media (max-width: $media-md) {
        padding: 30px 40px 18px;
    }

    &__header {
        color: var(--color-secondary-dark-blue);
        font: var(--font-xl);
        line-height: 42px;
        max-width: 400px;
        margin-bottom: 46px;
    }
    &__input {
        gap: 24px;
        margin-bottom: 30px;
        & label {
            color: var(--color-secondary-dark-blue);
            font: var(--font-m);
            white-space: nowrap;
        }

        & input {
            border-radius: 6px;
            border: 1px solid var(--color-secondary-dark-blue);
            padding: 11px 20px;
            width: 102%;
        }

        @media (max-width: $media-md) {
            flex-direction: column;
            align-items: start;
            gap: 10px;
        }
    }

    &__control {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-bottom: 46px;

        @media (max-width: $media-md) {
            flex-direction: column-reverse;
            gap: 10px;

            & button {
                width: 100%;
            }
        }
    }
    &__text {
        font: var(--font-m);
        color: var(--color-career-text);
        text-align: center;
        & a {
            border-bottom: 1px var(--color-career-text) solid;
            &:hover {
                color: var(--color-secondary-dark-blue);
                & a {
                    border-bottom: 1px var(--color-secondary-dark-blue) solid;
                }
            }
        }
    }
}

.follow {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 170px;

    @media (max-width: $media-md) {
        padding: 90px;
    }

    &__header {
        color: var(--color-secondary-dark-blue);
        font: var(--font-xl);
        text-align: center;

        max-width: 380px;
    }
}
