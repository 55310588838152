@import "../../shared/styles/variables/media";

.companyHistory {
    margin-bottom: var(--size-between-section);

    &__title {
        margin-bottom: var(--size-between-section);
    }

    &__carousel {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--color-career-text);
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;

        @media (max-width: $media-lg) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 30px;
        }
    }

    &__wrapperItem {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        border-radius: 20px;
        background-color: var(--color-career-light-blue);

        width: 260px;
        height: 300px;
    }

    &__wrapperYear {
        align-self: flex-end;

        padding-left: 12px;
        padding-bottom: 12px;
    }

    &__year {
        @media (max-width: $media-sm) {
            font: var(--font-infographics);
            font-weight: 500;
        }
    }

    &__img {
        position: absolute;
        bottom: 0;
        transform: translateX(-20px);
        margin-bottom: 20px;

        object-fit: contain;
    }

    &__description {
        white-space: pre-line;
        line-height: 20px;
        max-width: 420px;

        @media (max-width: $media-xl) {
            max-width: 310px;
        }

        @media (max-width: $media-md) {
            max-width: 260px;
        }
    }
}
