.personalDataAgreementModal {
    background-color: var(--color-white);
    border-radius: var(--radius-modal);

    padding: 30px;
    margin: 20px;

    &__close {
        height: 20px;
        width: 20px;
        cursor: pointer;
        display: block;

        background-image: url("../../../../../shared/assets/svg/icons/closeForm.svg");
        background-position: center;
        background-repeat: no-repeat;
        margin-left: auto;
        margin-bottom: 30px;
    }

    & > h2 {
        text-align: center;
        font-size: var(--size-large);
        line-height: var(--line-extraLarge);
        font-weight: var(--weight-regular);
        margin-bottom: 20px;
    }

    &__content {
        height: 300px;
        overflow: auto;
        padding: 10px;
        border: 1px solid #e3e7eb;

        margin-bottom: 20px;
    }

    &__buttonsWrapper {
        display: flex;
        justify-content: center;
        gap: 20px;
    }
}
