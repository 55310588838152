.accordionDirections {
    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        padding-top: 20px;
    }

    &__arrowDown {
        & > path {
            stroke: var(--color-primary-white);
        }
    }
}
