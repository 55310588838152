@import "../../shared/styles/mixins/image";
@import "../../shared/styles/variables/media";

.mainBanner {
    padding-top: 40px;
    margin-bottom: 130px;

    @media (max-width: $media-lg) {
        margin-bottom: 50px;
    }

    &__content {
        display: flex;
        gap: 10px;
        @media (max-width: $media-lg) {
            flex-direction: column;
            margin-bottom: 60px;
        }
    }

    &__img {
        @include background-img-full;
        border-radius: var(--border-radius-background);
    }

    &__wrapperTitle {
        display: flex;
        flex-direction: column;

        padding-left: var(--size-padding-container);
        & h1,
        h2 {
            line-height: 90px;
        }

        @media (max-width: $media-lg) {
            width: 100%;
            & h1,
            h2 {
                line-height: var(--line-extraLarge);
            }
            padding-left: 0;
        }
    }

    &__subTitle {
        padding-bottom: 60px;
        padding-right: 40px;

        @media (max-width: $media-lg) {
            max-width: 320px;
            padding-bottom: 10px;
            padding-right: 20px;
        }

        @media (max-width: $media-sm) {
            max-width: 220px;
        }
    }

    &__wrapperImg {
        position: relative;
        width: 100%;
    }

    &__wrapperSliceImg {
        width: 100px;
        height: 250px;

        @media (max-width: $media-lg) {
            width: auto;
            height: auto;
            margin-bottom: 240px;
        }
    }

    &__sticker {
        position: absolute;
        bottom: 0;
        right: 100px;

        @media (max-width: $media-lg) {
            right: 60px;
        }
    }

    &__buttonMobile {
        display: none;
        width: 100%;

        @media (max-width: $media-lg) {
            display: block;
        }
    }
}
