@import "../../../../shared/styles/variables/media";

.aboutMain {
    margin-bottom: 110px;
    padding-top: 40px;

    @media (max-width: $media-md) {
        margin-bottom: 40px;
        padding-top: 20px;
    }
}
