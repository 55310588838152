@import "../../shared/styles/variables/media";

.jobSwiper {
    margin-bottom: 200px;

    &__title {
        max-width: 840px;

        padding-left: var(--size-padding-container-x2);
        margin-bottom: 80px;

        @media (max-width: $media-sm) {
            margin-bottom: 60px;
        }
    }
    &__carousel {
        width: calc(100% - 140px);
        margin-left: 70px;
        @media (max-width: $media-lg) {
            width: 100%;
            margin-left: 0;
        }
    }
    &__navigation {
        @media (max-width: $media-sm) {
            display: none !important;
        }
    }

    @media (max-width: $media-sm) {
        margin-bottom: 100px;
    }
}
