@mixin img-full {
    position: absolute;
    left: 0;
    top: 0;
    z-index: var(--z-index-mask);

    width: 100%;
    height: 100%;

    object-fit: cover;
}

@mixin background-img-full {
    @include img-full;
    z-index: var(--z-index-backgound);

    border-radius: var(--border-radius-banner);
}
