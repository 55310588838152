.wrapper {
    box-sizing: border-box;
    background: linear-gradient(90deg, rgb(41, 112, 157), rgb(18, 55, 78));
    font-family: var(--font-family-candidates);
}

.content {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.2s;
    img {
        width: 100%;
        pointer-events: none;
    }
    @media (max-width: 1280px) {
        width: calc(100% - 120px);
    }
    @media (max-width: 767px) {
        width: calc(100% - 32px);
    }
    & i {
        font-weight: 500;
        font-size: 10px;
        line-height: 1.2;
        color: rgb(42, 42, 42);
    }
}

.start {
    height: 464px;
    margin-top: -48px;
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    align-content: center;
    position: relative;
}

.startBg {
    width: 523px;
    //background-image: url("../../../shared/assets/img/banner-x-2.png");
    position: absolute;
    bottom: 0;
    right: 0;
    height: 464px;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;

    transition:
        right 0.2s,
        opacity 0.2s;
    @media (max-width: 1023px) {
        right: -160px;
    }
    @media (max-width: 640px) {
        z-index: 0;
        opacity: 0.15;
        right: -280px;
    }
}

.startInfo {
    margin-top: 48px;
    color: #ffffff;
    flex-basis: 50%;
    max-width: 580px;
    width: 50%;
    @media (max-width: 640px) {
        width: 100%;
        max-width: 290px;
        z-index: 1;
        flex-basis: 100%;
    }
    &_tittle {
        font-size: 40px;
        line-height: 48px;
    }
    &_text {
        font-size: 16px;
        line-height: 1.25;
        margin-top: 16px;
        padding-bottom: 32px;
        opacity: 0.7;
    }
    &_button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-size: 16px;
        line-height: 1.25;
        padding-top: 18px;
        padding-bottom: 18px;
        width: 268px;
        position: relative;
        transition: opacity 0.2s;
        &:hover {
            opacity: 0.9;
            .info__button {
                transform: translateX(5px);
            }
        }
        .info__button {
            display: inline-block;
            width: 20px;
            height: 20px;
            position: relative;
            border-radius: 50%;
            vertical-align: middle;
            margin-left: 8px;
            transition: transform 0.2s;
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -3px;
                margin-left: -4px;
                width: 4px;
                height: 4px;

                transform: rotate(-45deg);
            }
        }
        a {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
        @media (max-width: 640px) {
            margin-left: auto;
            margin-right: auto;
        }
        background-color: #ffffff;
        color: #2a2a2a;
        .info__button {
            background-color: #2a2a2a;
            &::before {
                border: 1px solid #ffffff;
            }
        }
    }
}

.sectionStage {
    background-color: #ffffff;
    padding-top: 72px;
    padding-bottom: 72px;
    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 10px;
    }
}

.stage {
    &_title {
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: normal;
        line-height: 1.15;
        padding-bottom: 32px;
        font-size: 28px;
        text-transform: uppercase;
        padding-right: calc(100% - 400px);
    }
    &_items {
        display: flex;
        flex: 1 1 100%;
        flex-wrap: nowrap;
        margin-left: -32px;
        width: calc(100% + 32px);
        @media (max-width: 1023px) {
            margin-left: -32px;
            flex-wrap: wrap;
        }
        @media (max-width: 640px) {
            margin-left: 0;
            width: 100%;
        }
    }
    &_item {
        flex-basis: calc(100% / 4 - 32px);
        margin-left: 32px;
        padding: 20px;
        border-radius: 8px;
        min-height: 336px;
        @media (max-width: 1280px) {
            min-height: auto;
        }
        @media (max-width: 1023px) {
            flex-basis: calc(100% / 2 - 32px - 1px);
            max-width: calc(100% / 2 - 32px - 1px);
            margin-bottom: 32px;
        }
        @media (max-width: 640px) {
            flex-basis: 100%;
            width: 100%;
            margin-bottom: 16px;
            margin-left: 0;
            max-width: 100%;
        }
        background-color: #f4f7fa;
        &__text {
            font-size: 16px;
            line-height: 1.25;
            opacity: 0.7;
        }
    }
}

.section_doc {
    padding-top: 64px;
    padding-bottom: 40px;
    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 20px;
    }
}

.documents {
    color: #ffffff;
    &_title {
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: normal;
        padding-bottom: 16px;
        font-size: 28px;
        line-height: 1.15;
        text-transform: uppercase;
        padding-right: calc(100% - 400px);
    }
    &_description {
        max-width: 580px;
        font-size: 16px;
        line-height: 1.25;
        font-weight: 400;
        opacity: 0.7;
    }
    &_list {
        padding-top: 40px;
        margin-left: -32px;
        display: flex;
        flex: 1 1 100%;
        flex-wrap: wrap;
        @media (max-width: 640px) {
            margin-left: 0;
        }
        &__item {
            box-sizing: border-box;
            margin-left: 32px;
            margin-bottom: 24px;
            padding: 16px 20px 16px 64px;
            font-size: 16px;
            line-height: 1.25;
            font-weight: normal;
            position: relative;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            flex-basis: calc((100% - 32px - 1px) / 3 - 32px);
            width: calc((100% - 32px - 1px) / 3 - 32px);
            max-width: calc((100% - 32px - 1px) / 3 - 32px);
            min-width: calc((100% - 32px - 1px) / 3 - 32px);
            min-height: 92px;
            display: flex;
            align-content: center;
            align-items: center;
            align-self: center;
            transition: background-color 0.2s;
            text-decoration: none;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 20px;
                top: 50%;
                margin-top: -16px;
                width: 25px;
                height: 32px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            @media (max-width: 1280px) {
                flex-basis: calc((100% - 32px - 1px) / 2 - 32px);
                width: calc((100% - 32px - 1px) / 2 - 32px);
                max-width: calc((100% - 32px - 1px) / 2 - 32px);
                min-width: calc((100% - 32px - 1px) / 2 - 32px);
            }
            @media (max-width: 640px) {
                flex-basis: 100%;
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                margin-bottom: 16px;
                margin-left: 0;
            }
            color: #ffffff;
            &::before {
                background-image: url("../../../shared/assets/svg/icons/document.svg");
            }
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}

.item_link {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

.loader {
    margin-left: auto;
    margin-right: auto;
}
