.personalDataText {
    &__title {
        font-weight: var(--weight-bold);
    }

    &__table {
        border: 1px solid black;
        border-bottom: none;

        padding: 0 10px;

        &_end {
            border-bottom: 1px solid black;
        }
    }
}
