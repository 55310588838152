@import "../../../../shared/styles/variables/media";

.stickerVacancy {
    position: relative;
    z-index: var(--z-index-mask-higher);

    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        position: absolute;
        z-index: var(--z-index-mask);

        @media (max-width: $media-lg) {
            width: 150px;
        }
    }

    & > p {
        z-index: var(--z-index-mask-higher);
        max-width: 100px;

        transform: rotate(-10deg) translateX(3px);

        font: var(--font-l);
        text-align: center;

        & > span {
            font: var(--font-xl);
            font-weight: 500;
        }

        @media (max-width: $media-lg) {
            font: var(--font-m);
            max-width: 80px;

            & > span {
                font: var(--font-xl);
                font-weight: 500;
            }
        }
    }
}
