/* SuisseIntl */
@font-face {
    font-family: "SuisseIntl";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src:
        url("../../shared/assets/fonts/SuisseIntl-Regular.woff2") format("woff2"),
        url("../../shared/assets/fonts/SuisseIntl-Regular.woff") format("woff");
}

@font-face {
    font-family: "SuisseIntl";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src:
        url("../../shared/assets/fonts/SuisseIntl-Medium.woff2"),
        url("../../shared/assets/fonts/SuisseIntl-Medium.woff") format("woff");
}

/* TT interphases pro trl */
@font-face {
    font-family: "TT interphases pro trl";
    font-weight: 300;
    font-style: normal;
    font-display: block;
    src: url("../../shared/assets/fonts/TT Interphases Pro Trial Light.ttf");
}

@font-face {
    font-family: "TT interphases pro trl";
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url("../../shared/assets/fonts/TT Interphases Pro Trial Regular.ttf");
}

@font-face {
    font-family: "TT interphases pro trl";
    font-weight: 500;
    font-style: normal;
    font-display: block;
    src: url("../../shared/assets/fonts/TT Interphases Pro Trial Medium.ttf");
}

@font-face {
    font-family: "TT interphases pro trl";
    font-weight: 600;
    font-style: normal;
    font-display: block;
    src: url("../../shared/assets/fonts/TT Interphases Pro Trial DemiBold.ttf");
}

/* GraphikLC */

//@font-face {
//    font-family: "GraphikLC";
//    font-weight: 400;
//    font-style: normal;
//    font-display: swap;
//    src:
//            url("../../shared/assets/fonts/GraphikLC-Regular.woff2") format("woff2"),
//            url("../../shared/assets/fonts/GraphikLC-Regular.woff") format("woff");
//}
//
//@font-face {
//    font-family: "GraphikLC";
//    font-weight: 600;
//    font-style: normal;
//    font-display: swap;
//    src:
//            url("../../shared/assets/fonts/GraphikLC-Medium.woff2") format("woff2"),
//            url("../../shared/assets/fonts/GraphikLC-Medium.woff") format("woff");
//}
//
//@font-face {
//    font-family: "GraphikLC";
//    font-weight: 800;
//    font-style: normal;
//    font-display: swap;
//    src:
//            url("../../shared/assets/fonts/GraphikLC-Bold.woff2") format("woff2"),
//            url("../../shared/assets/fonts/GraphikLC-Bold.woff") format("woff");
//}
