@import "../../shared/styles/mixins/image";
@import "../../shared/styles/variables/media";

.articleMain {
    padding-top: 60px;
    margin-bottom: var(--size-between-section);

    @media (max-width: $media-sm) {
        padding-top: 20px;
    }

    &__blog {
        display: flex;
        flex-direction: column;
        gap: 20px;

        margin-bottom: 40px;
    }

    &__poster {
        position: relative;

        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        height: 400px;

        margin-bottom: var(--size-between-section);
    }

    &__img {
        @include background-img-full;
    }

    &__share {
        padding-top: 20px;
        padding-left: 20px;

        @media (max-width: $media-sm) {
            padding-top: 10px;
            padding-left: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 80px;
        max-width: 680px;

        @media (max-width: $media-sm) {
            gap: 40px;
        }
    }

    &__date {
        font: var(--font-l);
        color: var(--secondary-secondary-grey-3);

        padding-top: 20px;
    }

    &__desc {
        white-space: pre-line;
    }
}
