@mixin scroll {
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
        background-color: var(--color-primary-white);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: var(--color-secondary-grey);
    }
}
