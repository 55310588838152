@import "../../../../shared/styles/variables/media";

.vacancyTitle {
    padding-top: 60px;
    margin-bottom: 50px;

    @media (max-width: $media-md) {
        padding-top: 30px;
        margin-bottom: 40px;
    }

    &__name {
        font: var(--font-xl);

        padding-top: 30px;
        margin-bottom: 10px;
    }

    &__location {
        font: var(--font-l);
    }
}

.skeleton {
    &__name {
        height: var(--font-line-xl);
        max-width: 400px;

        margin-top: 30px;
        margin-bottom: 10px;
    }
    &__location {
        height: var(--font-line-l);
        max-width: 240px;
    }
}
