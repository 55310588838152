@import "../../../../shared/styles/mixins/image";
@import "../../../../shared/styles/variables/media";

.aboutMain {
    &__img {
        @include background-img-full;
    }

    &__wrapperTitle {
        margin-bottom: 500px;
    }

    &__title {
        max-width: 820px;
        padding-left: var(--size-padding-container-x2);
        padding-right: var(--size-padding-container);
        padding-bottom: var(--size-padding-container);

        @media (max-width: $media-lg) {
            max-width: 620px;
        }
    }

    &__description {
        padding-inline: var(--size-padding-container-x2);
        padding-bottom: 30px;

        text-align: end;
        color: var(--color-primary-white);
    }
}
