@import "../variables/media";

@mixin gridStats {
    .grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr) 1.5fr;
        grid-template-rows: repeat(3, 75px) repeat(2, 64px);
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        @media (max-width: $media-lg) {
            display: flex;
            flex-direction: column;
        }
    }

    .block {
        border-radius: var(--border-radius-card-left) var(--border-radius-m) var(--border-radius-m)
            var(--border-radius-m);
        padding: 30px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--color-primary-blue);
        @media (max-width: $media-lg) {
            padding: 24px 22px;
            min-height: 190px;
        }

        &__title {
            color: var(--color-primary-cyan);

            @media (max-width: $media-xl) {
                font: var(--font-infographics);
                font-weight: 500;
            }

            @media (max-width: $media-lg) {
                font: var(--font-infographics);
                font-weight: 500;
            }
        }

        & > p {
            text-align: end;
            font: var(--font-xl);
            color: var(--color-primary-white);
        }

        &_first {
            grid-area: 1 / 1 / 4 / 3;
        }

        &_second {
            grid-area: 1 / 3 / 4 / 6;
        }

        &_third {
            grid-area: 1 / 6 / 6 / 7;

            & > p {
                text-align: start;
            }

            @media (max-width: $media-lg) {
                & > p {
                    text-align: end;
                }
            }
        }

        &_fourth {
            grid-area: 4 / 1 / 6 / 6;

            flex-direction: row;
            align-items: center;

            & > p {
                display: flex;
                align-items: flex-end;
                height: 100%;
            }

            @media (max-width: $media-lg) {
                flex-direction: column;
                justify-content: space-between;
                align-items: start;

                margin-bottom: 20px;

                & > p {
                    align-self: flex-end;
                }
            }
        }

        &_fifth {
            grid-area: 5 / 6 / 6 / 7;

            width: 100%;
        }
    }
}
