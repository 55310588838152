@import "../../../../shared/styles/variables/media";

.queryVacancy {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__searchWrapper {
        display: flex;
        gap: 10px;
    }

    &__input {
        width: 100%;
    }
}
