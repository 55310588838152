@import "../../../../shared/styles/variables/media";

.teamItem {
    position: relative;
    width: 100%;

    background-color: var(--color-career-light-blue);
    border-radius: var(--border-radius-m);

    &__content {
        padding: 40px 30px;
    }

    &__text {
        font: var(--font-xl);
        margin-bottom: 18px;

        & > span {
            color: var(--color-career-text);
        }
    }

    &__wrapperLink {
        position: absolute;
        bottom: 0;
        right: 0;

        padding-top: 20px;
        padding-left: 20px;

        @media (max-width: $media-sm) {
            padding-top: 10px;
            padding-left: 10px;
        }
    }
}

.skeleton {
    width: 100%;
    height: 180px;
    border-radius: var(--border-radius-m);
}
