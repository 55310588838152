.personalDataText {
    &__title {
        font-weight: 600;
    }

    &__table {
        border: 1px solid black;
        border-bottom: none;

        padding: 0 10px;

        &_end {
            border-bottom: 1px solid black;
        }
    }
}
