@import "../../shared/styles/variables/media";

.companyInside {
    margin-bottom: var(--size-between-section);

    background-color: var(--color-career-light-blue);
    border-radius: var(--border-radius-background);

    &__wrapper {
        display: flex;
        gap: 150px;
        margin-bottom: 60px;

        @media (max-width: $media-lg) {
            gap: 100px;
        }

        @media (max-width: $media-md) {
            flex-direction: column;
            gap: 20px;

            max-width: 76%;

            margin-bottom: 30px;
        }

        &_button {
            margin-bottom: 190px;
        }
    }

    &__title {
        padding: var(--size-padding-container) var(--size-padding-container) var(--size-padding-container)
            var(--size-padding-container-x2);

        @media (max-width: $media-md) {
            padding: 0 var(--size-padding-container) var(--size-padding-container) var(--size-padding-container-x2);
        }
    }

    &__descriptionWrapper {
        position: relative;

        align-self: flex-end;

        max-width: 380px;
        margin-right: var(--size-padding-container);
        margin-bottom: var(--size-padding-container);

        font: var(--font-l);

        @media (max-width: $media-md) {
            align-self: initial;
            max-width: initial;
            margin: 0;

            padding-left: var(--size-padding-container);
        }
    }

    &__carousel {
        @media (max-width: $media-md) {
            padding-bottom: 60px;
        }
    }

    &__button {
        position: absolute;
        padding-inline: 46px;
        bottom: calc(-64px + -24px);
        left: 0;
    }

    &__buttonMobile {
        padding-inline: var(--size-padding-container);

        & > a {
            width: 100%;
            margin-bottom: 40px;
        }
    }
}
