@import "../../../../shared/styles/variables/media";

.searchVacancies {
    padding-top: 60px;
    margin-bottom: 80px;

    @media (max-width: $media-md) {
        background-color: var(--color-career-light-blue);
        border-radius: var(--border-radius-s);
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &__text {
        text-align: center;
        font: var(--font-xl);
    }
}
