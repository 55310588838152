@import "../../../../shared/styles/mixins/image";

.aboutMain {
    &__wrapperTitle {
        margin-bottom: 240px;
    }

    &__title {
        max-width: 820px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    &__wrapperImg {
        position: relative;

        margin-bottom: 10px;
    }

    &__img {
        @include background-img-full;
    }

    &__description {
        text-align: end;
        color: var(--color-career-text);
        font: var(--font-s);
    }
}
