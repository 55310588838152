@import "fonts";
@import "reset";
@import "./variables/global";

* {
    box-sizing: border-box;
    scrollbar-gutter: stable;
}

body {
    font: var(--font-m);
    background-color: var(--color-background);
    color: var(--color-primary-dark-blue);
}

/* сброс размеров swiper-slide для изменения размера slice */
.swiper-slide {
    width: auto;
    height: auto;
}
