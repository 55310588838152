.directionList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    margin-right: 50px;

    &__item {
        padding: 10px 30px;
        border-radius: var(--border-radius-banner);
        border: 1px solid var(--color-primary-dark-blue);

        font: var(--font-m);
    }
}

.skeleton {
    &__item {
        width: 160px;
        height: 46px;

        border-radius: var(--border-radius-banner);
    }
}
