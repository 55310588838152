@import "../../shared/styles/variables/media";

.container {
    margin-bottom: var(--size-between-section);
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: var(--z-index-mask);

    position: relative;
    overflow: hidden;

    width: 100%;
    padding-top: 130px;
    padding-inline: var(--size-padding-container);
    border-radius: var(--border-radius-banner);
    background-color: var(--color-career-galaxy-purple);
    min-height: 395px;
    text-align: center;

    &__imgBanner {
        position: absolute;
        z-index: var(--z-index-backgound);

        &_one {
            top: 0;
            right: -18px;
        }

        &_two {
            bottom: 0;
            left: 0;
        }

        &_three {
            top: -9px;
            left: 84px;
        }

        @media (max-width: 1300px) {
            display: none;
        }
    }

    &__title {
        margin-bottom: 24px;
        color: var(--color-primary-white);
        @media (max-width: $media-md) {
            font: var(--font-xxl);
            margin-bottom: 46px;
            margin-left: 0;
        }
    }

    &__description {
        max-width: 520px;

        margin-bottom: 35px;

        font: var(--font-l);
        color: var(--color-primary-white);

        @media (max-width: $media-md) {
            font-size: 18px;
            margin-bottom: 50px;
        }
    }

    &__wrapperButtons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 40px;

        margin-bottom: 40px;

        @media (max-width: $media-md) {
            margin-bottom: 60px;
        }
    }
}
