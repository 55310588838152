.submitButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__text {
        text-align: center;
        color: var(--color-red);
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        gap: 50px;

        margin-bottom: 50px;
    }
}
