.loader {
    width: 80px;
    height: 80px;
    margin: 0;
    background: transparent;
    border-top: 0.4rem solid var(--color-primary-blue);
    border-right: 0.4rem solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
