@import "../../../../styles/fuctions/randomLength";

.skeleton {
    $divCount: 50;
    $minLength: 200px;
    $maxLength: 680px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    @for $i from 1 through $divCount {
        &__item_#{$i} {
            width: randomLength($minLength, $maxLength);
            height: var(--font-size-m);
        }
    }
}
