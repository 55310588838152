.row {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    & > * {
        flex-basis: 48%;
    }

    @media (max-width: 468px) {
        justify-content: normal;
        flex-direction: column;
    }
}
