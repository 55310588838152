@import "../../../../shared/styles/variables/media";
@import "../../../../shared/styles/mixins/textFieldBase";
$sizeLabel: 40px;

@mixin label {
    & > p,
    label {
        max-width: 175px;
        width: 100%;
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 60px;

    max-width: 680px;

    &__title {
        font: var(--font-l);
        margin-bottom: 30px;

        @media (max-width: $media-md) {
            font: var(--font-xl);
            font-weight: 500;
        }
    }

    &__required {
        color: var(--color-career-text);
        margin-bottom: 30px;

        &_other {
            color: var(--color-primary-dark-blue);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: $sizeLabel;

        &_checkbox {
            gap: 30px;
        }
    }

    & label {
        @include label;
    }

    &__personalData {
        text-decoration: underline;
    }

    &__row {
        display: flex;
        align-items: center;
        gap: $sizeLabel;

        @include label;

        @media (max-width: $media-md) {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
        }
    }

    &__textField {
        @include label;
        @media (max-width: $media-md) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
        &_citiesTextField {
            display: flex;
            align-items: center;
            gap: 30px;
            & input {
                @include textFieldBase;
                &::placeholder {
                    color: #757575;
                }
            }
            @media (max-width: $media-md) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
        }
        &_cities {
            & ul {
                right: 0;
                width: calc(100% - 205px);
                background: #ffffff;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
                border-radius: 12px;
                z-index: 3;
                overflow: hidden;
                @media (max-width: $media-md) {
                    width: 100%;
                }
            }
        }
    }

    &__phoneCode {
        max-width: 90px;
    }

    &__submit {
        margin-bottom: 80px;

        @media (max-width: $media-md) {
            width: 100%;
        }
    }
}

.link {
    text-decoration: underline;
    cursor: pointer;
}
