$size: 16px;
$size-x2: $size * 2;

.buttonDropdown {
    display: flex;

    cursor: pointer;

    &__arrowDown {
        transform: rotate(0deg);

        transition: transform 0.2s ease-in-out;

        &_red {
            & path {
                stroke: var(--color-primary-white);
            }
        }
    }

    &:hover {
        & > .buttonDropdown__arrowDown {
            transform: rotate(180deg);

            transition: transform 0.2s ease-in-out;

            & path {
                stroke: var(--color-secondary-grey-2);
            }
        }
    }
}

.dropdown {
    position: absolute;
    z-index: var(--z-index-modal);
    top: 70%;
    left: 0;
    padding: $size;

    &__options {
        position: relative;

        padding: $size-x2 $size-x2 $size;

        background-color: var(--color-primary-white);
        box-shadow: var(--shadow-modal);
        border-radius: var(--border-radius-m);

        display: grid;
        grid-template-columns: repeat(3, 250px);
        grid-template-rows: repeat(5, 56px);
        grid-column-gap: $size-x2;
        grid-row-gap: $size;
    }

    &__item {
        display: flex;
        gap: 10px;

        font: var(--font-m);
        font-weight: 600;
        color: var(--color-primary-dark-blue);

        border-radius: var(--border-radius-m);

        &:hover {
            color: var(--color-secondary-grey-2);
        }
    }
}
