@import "../../../../../shared/styles/mixins/other";

$paddingOptions: 24px;
$paddingOptions-x2: $paddingOptions * 2;
$border-radius: 20px;

.options {
    position: absolute;
    top: 120%;
    left: 0;
    z-index: var(--z-index-select);

    width: 290px;

    border-radius: $border-radius;

    padding-top: $paddingOptions;

    padding-bottom: $paddingOptions;

    background-color: var(--color-primary-white);
    box-shadow: var(--shadow-modal);

    & > ul {
        display: flex;
        flex-direction: column;
        gap: $paddingOptions;
        padding-inline: $paddingOptions;
        max-height: 380px;

        overflow: hidden;
    }

    &_viewScroll {
        & > ul {
            @include scroll;
        }
    }

    &__item {
        cursor: pointer;
        font: var(--font-l);

        &:hover {
            color: var(--color-primary-blue);
        }

        &_current {
            color: var(--color-career-text);
        }

        //тип button в dropdown
        &_button {
            font: var(--font-m);
            font-weight: 500;
            line-height: 18px;
        }
    }

    //тип button в dropdown
    &_button {
        width: 100%;
    }
}
