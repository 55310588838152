.checkbox {
    color: var(--color-blue);
    font-weight: var(--weight-medium);

    &__wrapper {
        display: flex;
        align-items: flex-start;
        gap: 10px;
    }

    &__input {
        width: 20px;
        height: 20px;
        padding: 2px;

        flex-shrink: 0;

        appearance: none;
        cursor: pointer;

        border: 2px solid rgba(102, 112, 128, 0.3);
        border-radius: 4px;

        transition: all 0.1s ease-in-out;

        overflow: hidden;

        &_radio {
            border-radius: var(--radius-circle);
        }

        &:hover,
        &:focus {
            border-color: var(--color-blue);

            transition: all 0.1s ease-in-out;
        }

        &:checked {
            border: none;
            position: relative;

            transition: all 0.1s ease-in-out;
            &::before {
                position: absolute;
                content: "";

                display: block;
                width: 20px;
                height: 20px;

                z-index: var(--zIndex-minimal);

                background-image: url("../../../../../shared/assets/svg/icons/check form.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}
