.checkbox {
    width: fit-content;

    &__wrapper {
        display: flex;
        align-items: flex-start;
        gap: 16px;
    }

    &__input {
        width: 24px;
        height: 24px;
        padding: 2px;

        flex-shrink: 0;

        appearance: none;
        cursor: pointer;

        border: 2px solid rgba(102, 112, 128, 0.3);
        border-radius: 3px;

        transition: all 0.1s ease-in-out;

        &:hover,
        &:focus {
            border-color: var(--color-primary-dark-blue);

            transition: all 0.1s ease-in-out;
        }

        &:checked {
            background-color: var(--color-primary-dark-blue);
            border-color: var(--color-primary-dark-blue);

            transition: all 0.1s ease-in-out;
            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M8.50005 12.379L15.3941 5.48425L16.4553 6.54475L8.50005 14.5L3.72705 9.727L4.78755 8.6665L8.50005 12.379Z' fill='white'/%3E%3C/svg%3E");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                width: 24px;
                height: 24px;
            }
        }
    }
}
