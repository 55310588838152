.baseLabel {
    display: flex;
    align-items: center;
    gap: 30px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
