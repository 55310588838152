@import "../../shared/styles/variables/media";

.faq {
    margin-bottom: var(--size-between-section);

    &__title {
        font: var(--font-title-s);
        margin-bottom: 20px;

        &_step {
            font: var(--font-title-s);
            margin-bottom: 40px;
        }
    }
    &__description {
        font: var(--font-size-l);
        margin-bottom: 40px;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 80px;

    @media (max-width: $media-sm) {
        gap: 40px;
    }
}

.accordionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 17px;

    & a {
        color: var(--color-primary-blue);
    }

    & li {
        list-style-type: disc;
    }
    & ul {
        padding-top: 8px;
        padding-left: 22px;
    }

    &__item {
        border-radius: var(--border-radius-m);
        padding: 24px 40px;
        display: flex;
        gap: 50px;
        flex-direction: column;

        background: var(--color-background);

        color: var(--color-primary-dark-blue);
        font: var(--font-xsl);
        font-weight: 500;

        & > svg {
            align-self: start;
        }

        @media (max-width: $media-sm) {
            gap: 30px;

            padding: 20px 20px;
        }
    }

    &__content {
        font: var(--font-l);
        padding-right: 85px;

        @media (max-width: $media-sm) {
            padding-right: 44px;
        }
    }
}
