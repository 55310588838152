.skeleton {
    border-radius: var(--border-radius-m);
    background: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
    background-size: 200% 100%;
    animation: shimmer 1s infinite;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
