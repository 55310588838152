.formBlock {
    background-color: var(--color-white);
    border-radius: var(--radius-medium);

    padding: 20px;
    margin-bottom: 20px;

    box-shadow: var(--shadow-blog);

    position: relative;

    &__title {
        font-weight: 800;
        margin-bottom: 10px;

        &_center {
            text-align: center;
        }

        &_start {
            text-align: start;
        }
    }

    &__spaceButtons {
        max-width: 76%;
    }

    &__container {
        max-width: 700px;
        margin: 0 auto;
        padding: 50px 0;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
