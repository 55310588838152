.tooltip {
    position: relative;

    &__text {
        visibility: hidden;
        position: absolute;
        z-index: var(--z-index-select);
        border-radius: var(--border-radius-m);
        background-color: var(--color-primary-white);
        padding: 14px 21px;
        box-shadow: var(--shadow-modal);
    }

    &:hover &__text {
        visibility: visible;
    }
}

.bottom {
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
}

.top {
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
}

.left {
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 8px);
}

.right {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 8px);
}
