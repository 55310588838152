.autocomplete {
    position: relative;

    &__dropdown {
        position: absolute;
        top: calc(100% + 10px);

        width: 100%;
        background: var(--color-white);
        box-shadow: var(--shadow-modal);
        border-radius: var(--radius-medium);
        z-index: var(--z-index-select);
        overflow: hidden;
    }

    &__item {
        padding: 16px;

        color: var(--color-black);
        font-weight: var(--weight-medium);

        cursor: pointer;

        &:hover {
            background-color: var(--color-border);
        }
    }
}
