@import "../../shared/styles/variables/media";

.blogsPage {
    margin-top: 20px;

    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    background-color: var(--color-career-light-blue);
    margin-bottom: 120px;

    &__wrapper {
        display: inline-flex;
        justify-content: space-between;

        margin-bottom: 75px;

        @media (max-width: $media-md) {
            display: block;
            margin-bottom: 50px;
        }
    }

    &__wrapperTitle {
        padding: var(--size-padding-container) var(--size-padding-container) var(--size-padding-container)
            var(--size-padding-container-x2);

        max-width: 580px;

        @media (max-width: $media-md) {
            max-width: 310px;
            margin-bottom: 30px;
        }

        @media (max-width: $media-sm) {
            max-width: 220px;
            margin-bottom: 30px;
        }
    }

    &__description {
        align-self: flex-end;

        padding: var(--size-padding-container) var(--size-padding-container-x2) var(--size-padding-container)
            var(--size-padding-container);

        font: var(--font-l);
        max-width: 580px;

        @media (max-width: $media-md) {
            padding: 0;
            padding-inline: 40px;
        }
    }

    &__container {
        display: flex;
        justify-content: flex-end;

        @media (max-width: $media-md) {
            justify-content: center;
        }
    }

    &__blogList {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 60px;

        margin-bottom: 80px;

        @media (max-width: $media-xl) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $media-md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
