@import "../../../../shared/styles/variables/media";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 20px;

    &__containerNav {
        display: flex;
        align-items: center;
        column-gap: 30px;

        & > nav {
            display: flex;
            column-gap: 40px;

            @media (max-width: $media-xl) {
                column-gap: 20px;
            }

            @media (max-width: $media-lg) {
                display: none;
            }
        }

        @media (max-width: $media-xl) {
            column-gap: 0;
        }
    }

    &__link {
        font-weight: 500;

        &:hover {
            color: var(--color-secondary-grey-2);
        }

        @media (hover: none) {
            &:hover {
                color: initial;
            }
        }
    }

    &__logo {
        transform: translateX(-20px);
    }

    &__button {
        margin-left: auto;
        margin-right: 0;

        @media (max-width: $media-lg) {
            display: none;
        }
    }
}
