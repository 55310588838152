.lifehacks {
    margin-bottom: 160px;

    background-color: var(--color-career-galaxy-purple);
    border-radius: var(--border-radius-background);

    &__wrapper {
        display: flex;
        gap: 150px;
        margin-bottom: 70px;
    }
    &__sliceBorder {
        background: var(--color-career-CTA);
        & svg path {
            fill: var(--color-career-CTA);
        }
    }
    &__title {
        padding: var(--size-padding-container) var(--size-padding-container) var(--size-padding-container)
            var(--size-padding-container-x2);
    }

    &__descriptionWrapper {
        position: relative;

        align-self: flex-end;
        color: var(--color-primary-white);
        max-width: 380px;
        margin-right: var(--size-padding-container);
        margin-bottom: var(--size-padding-container);
    }

    &__description {
        font: var(--font-l);
    }

    &__button {
        position: absolute;
        padding-inline: 46px;
        bottom: calc(-64px + -24px);
        left: 0;
    }
}
