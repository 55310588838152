@import "../../../shared/styles/variables/media";

.button {
    background-color: transparent;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    padding: 19px 34px;

    border-radius: var(--border-radius-m);

    text-align: center;

    transition: all 0.1s ease-in-out;

    cursor: pointer;

    &_m {
        font: var(--font-button);
        font-weight: 400;
    }

    &_s {
        font: var(--font-m);
        font-weight: 400;
    }

    &__loadingSvg {
        animation: spin 1s linear infinite;
    }

    &_blue {
        background-color: var(--color-primary-blue);
        border: 1.2px solid var(--color-primary-blue);

        color: var(--color-primary-cyan);

        transition: all 0.1s ease-in-out;

        &:hover {
            color: var(--color-primary-blue);
            background-color: var(--color-primary-white);

            transition: all 0.1s ease-in-out;

            & > svg {
                & path {
                    stroke: var(--color-primary-blue);
                }
            }
        }

        @media (hover: none) {
            &:hover {
                color: var(--color-primary-white);
                background-color: var(--color-primary-blue);
            }
        }
    }

    &_transparent-white {
        color: var(--color-primary-white);
        border: 1.2px solid var(--color-primary-white);

        transition: all 0.1s ease-in-out;
        &:hover {
            color: var(--color-primary-dark-blue);
            background-color: var(--color-primary-white);

            transition: all 0.1s ease-in-out;
        }

        @media (hover: none) {
            &:hover {
                color: var(--color-primary-white);
                background-color: initial;
            }
        }
    }

    &_transparent-dark {
        border: 1.2px solid var(--color-primary-dark-blue);

        transition: all 0.1s ease-in-out;

        &:hover {
            color: var(--color-primary-white);
            background-color: var(--color-primary-dark-blue);

            transition: all 0.1s ease-in-out;
        }

        @media (hover: none) {
            &:hover {
                color: initial;
                background-color: initial;
            }
        }
    }

    &_gray {
        color: var(--color-career-text);
        background-color: var(--color-career-hidden);
        border: 1.2px solid var(--color-career-hidden);

        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: var(--color-primary-blue);
            border: 1.2px solid var(--color-primary-blue);
            color: var(--color-primary-white);

            transition: all 0.1s ease-in-out;
        }

        @media (hover: none) {
            &:hover {
                color: var(--color-career-text);
                background-color: var(--color-career-hidden);
            }
        }
    }

    &_disabled {
        color: var(--color-career-text);
        background-color: var(--color-career-hidden);
        border-color: var(--color-career-hidden);

        cursor: not-allowed;

        &:hover {
            color: var(--color-career-text);
            background-color: var(--color-career-hidden);
            border-color: var(--color-career-hidden);

            cursor: not-allowed;
        }
    }

    & > svg {
        flex-shrink: 0;
        height: 1em;
    }

    @media (max-width: $media-md) {
        padding: 16px 34px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
