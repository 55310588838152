@import "../../shared/styles/variables/media";

.joinWork {
    margin-bottom: var(--size-between-section);

    &__list {
        margin-bottom: 30px;
    }

    &__vacancy {
        font: var(--font-xl);
        margin-bottom: 15px;
    }

    &__title {
        margin-bottom: 58px;
    }

    &__button {
        @media (max-width: $media-md) {
            width: 100%;
        }
    }
}
