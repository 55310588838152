@import "../../shared/styles/variables/media";

.vacancyMain {
    margin-bottom: 75px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 45px;
        max-width: 676px;
    }

    &__desc {
        padding: 0 !important;
        overflow-y: unset !important;
        line-height: 24px !important;
        margin-top: 3.2rem;
        & h1 {
            font: var(--font-xxl);
        }
        & h2 {
            font: var(--font-xl);
        }
        & h3 {
            font: var(--font-xsl);
        }
        & h4 {
            font: var(--font-l);
        }
        & a {
            text-decoration: underline;
            color: var(--color-primary-blue) !important;
        }
        & em {
            font-style: italic;
        }
        & strong {
            font-weight: bold;
        }
        & li {
            &::before {
                text-align: center !important;
            }
        }
        &_sample {
            p {
                margin-bottom: 1rem;
            }
        }
    }

    &__subTitle {
        color: var(--color-career-text);
        margin: 3rem auto;
    }

    &__buttonList {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @media (max-width: $media-md) {
            flex-direction: column;
        }
    }

    &__share {
        @media (max-width: $media-md) {
            width: 100%;
        }
    }
}

.banner {
    background-color: var(--color-primary-blue);
    border-radius: 20px;
    padding: 1.8rem 1.2rem 1.8rem 1.8rem;
    display: flex;
    justify-content: space-between;
    @media (max-width: $media-md) {
        flex-direction: column;
        gap: 2rem;
    }
    &__left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
            color: var(--color-primary-white);
            font: var(--font-xsl);
        }
        @media (max-width: $media-md) {
            svg {
                display: none;
            }
        }
    }
    &__right {
        img {
            border-radius: 10px;
            @media (max-width: $media-md) {
                width: 290px;
                max-width: 100%;
            }
        }
    }
}

.footer {
    background-color: var(--color-primary-blue);
    border-radius: 20px;
    padding: 1.8rem 3rem 1.8rem 2.2rem;
    h3 {
        color: var(--color-primary-white);
        font: var(--font-xsl);
        @media (max-width: $media-md) {
            text-align: center;
        }
    }
    @media (max-width: $media-md) {
        border-radius: 12px;
    }
    &__list {
        display: flex;
        justify-content: space-between;
        gap: 3rem;
        margin-top: 2.1rem;
        @media (max-width: $media-lg) {
            gap: 0.6rem;
        }
        @media (max-width: $media-md) {
            flex-direction: column;
            align-items: center;
            gap: 2rem;
        }

        &_item {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            @media (max-width: $media-md) {
                align-items: center;
                gap: 0.6rem;
            }
            & p {
                color: var(--color-primary-white);
                @media (max-width: $media-md) {
                    text-align: center;
                }
            }
        }
    }
}
