.lifehack {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: var(--border-radius-card-left) var(--border-radius-m) var(--border-radius-m) var(--border-radius-m);
    height: 400px;
    width: 290px;
    background-color: var(--color-primary-white);
    color: var(--color-primary-dark-blue);
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    &__title {
        font: var(--font-xl);
    }
    &__description {
        font: var(--font-l);
    }
}
