.numberField {
    &__label {
        font-size: var(--size-small);
        color: var(--color-lightBlue);

        & > span {
            color: var(--color-red);
        }
    }

    &__input {
        width: 100%;
        padding: var(--formPaddingDefault);

        border-radius: var(--radius-default);
        border: 1px solid var(--color-border);

        transition: var(--transition-default);

        &:focus,
        &:active {
            transition: var(--transition-default);
            border-color: var(--color-blue);
        }

        &::placeholder {
            color: var(--color-bizText);
            font-family: var(--font-futura);
            font-weight: var(--weight-medium);
            font-size: var(--size-regular);
        }

        &_error {
            border-color: var(--color-red);
        }
    }
}
