.addSubtractButtons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 20px;

    @media (max-width: 468px) {
        flex-direction: column;
    }
}
