.loadingFull {
    align-items: center;
    display: flex;
    justify-content: space-around;

    position: fixed;
    z-index: var(--zIndex-modal);
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;

    &__spiner {
        width: 80px;
        height: 80px;
        margin: 0;
        background: transparent;
        border-top: 4px solid var(--color-blue);
        border-right: 4px solid transparent;
        border-radius: 50%;
        animation: 1s spin linear infinite;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
