@import "../../shared/styles/variables/media";

.investmentStage {
    margin-bottom: 170px;

    @media (max-width: $media-md) {
        margin-bottom: 80px;
    }

    &__investInfo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;

        min-height: 110px;

        margin-bottom: 150px;

        @media (max-width: $media-md) {
            margin-bottom: 40px;
        }
    }

    &__description {
        font: var(--font-l);
        align-self: flex-end;

        max-width: 490px;

        @media (max-width: $media-md) {
            max-width: initial;
        }
    }

    &__stageList {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        @media (max-width: $media-md) {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
        }
    }

    &__stageItem {
        display: flex;
        gap: 30px;

        @media (max-width: $media-md) {
            gap: 12px;
        }
    }

    &__stageNumber {
        font: var(--font-title);
        font-weight: 500;

        min-width: 70px;

        @media (max-width: $media-md) {
            flex-shrink: 0;

            font-size: 90px;
            line-height: 90px;
            font-weight: 500;
        }
    }

    &__stageDesc {
        max-width: 330px;

        font: var(--font-l);

        @media (max-width: $media-md) {
            max-width: initial;
        }
    }
    &__stageTitle {
        font: var(--font-xxl);
        margin-bottom: 20px;
    }
}
