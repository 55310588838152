@import "../../../../../shared/styles/variables/media";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    @media (max-width: $media-sm) {
        align-items: start;
        gap: 20px;
    }
}

.header {
    font: var(--font-xl);

    @media (max-width: $media-sm) {
        font-size: 18px;
    }
}

.step {
    position: relative;

    width: 380px;
    height: 407px;
    background-color: var(--color-primary-blue);
    border-top-left-radius: var(--border-radius-card-left);
    border-top-right-radius: var(--border-radius-m);
    border-bottom-left-radius: var(--border-radius-m);
    color: var(--color-primary-white);

    transition: background-color 0.1s ease-in-out;

    @media (max-width: $media-sm) {
        width: 280px;
        height: 302px;
    }

    &__container {
        padding-top: 38px;
        padding-left: 37px;
        padding-bottom: 33px;

        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: $media-sm) {
            padding-top: 26px;
            padding-left: 21px;
            padding-bottom: 26px;
        }
    }

    &__svg {
        @media (max-width: $media-sm) {
            width: 60px;
            height: 60px;
        }
    }

    &__title {
        max-width: 230px;
        min-height: 90px;
        padding-top: 20px;

        font: var(--font-xxl);
        font-weight: 500;

        @media (max-width: $media-sm) {
            padding-top: 10px;
        }
    }

    &__description {
        width: 210px;
        font: var(--font-m);

        @media (max-width: $media-sm) {
            width: 190px;
        }
    }

    &__wrapperButton {
        position: absolute;
        bottom: 0;
        right: 0;

        padding-top: 20px;
        padding-left: 20px;

        @media (max-width: $media-sm) {
            padding-top: 10px;
            padding-left: 10px;
        }
    }

    &:hover {
        background-color: var(--color-primary-white);
        color: var(--color-primary-dark-blue);
        transition: background-color 0.1s ease-in-out;
        & a {
            color: var(--color-primary-blue);
        }
    }
}
