@import "../../../shared/styles/variables/media";

.iconButton {
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 15px;

    & > svg {
        height: 64px;
        width: 64px;
    }

    & > span {
        font: var(--font-l);
    }

    @media (max-width: $media-md) {
        & > svg {
            height: 50px;
            width: 50px;
        }
    }

    &_left {
        flex-direction: row-reverse;
        margin-right: auto;
    }

    &_dark {
        &:hover {
            & path {
                stroke: var(--color-primary-dark-blue);
            }
        }

        @media (hover: none) {
            &:hover {
                & path {
                    stroke: var(--color-primary-blue);
                }
            }
        }
    }
    &_blue {
        &:hover {
            & svg {
                fill: var(--color-primary-blue);
            }
            & path {
                stroke: var(--color-primary-cyan);
            }
            & rect {
                stroke: var(--color-primary-blue);
            }
        }

        @media (hover: none) {
            & svg {
                fill: var(--color-primary-blue);
            }
            & path {
                stroke: var(--color-primary-cyan);
            }
            & rect {
                stroke: var(--color-primary-blue);
            }
        }
    }

    &_white {
        & > svg {
            fill: var(--color-primary-white);

            & > rect {
                stroke: none;
            }
        }

        &:hover {
            & path {
                stroke: var(--color-primary-dark-blue);
            }
        }

        @media (hover: none) {
            &:hover {
                & path {
                    stroke: var(--color-primary-blue);
                }
            }
        }
    }

    &_light {
        & > svg {
            & > rect {
                stroke: var(--color-primary-white);
            }

            & > path {
                stroke: var(--color-primary-white);
            }
        }
    }

    &_lightStrokeBlue {
        & > svg {
            & > rect {
                stroke: var(--color-primary-white);
            }
        }

        &:hover {
            & path {
                stroke: var(--color-primary-white);
            }
        }

        @media (hover: none) {
            &:hover {
                & path {
                    stroke: var(--color-primary-blue);
                }
            }
        }
    }
}
