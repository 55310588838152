@import "../../shared/styles/variables/media";

.vacanciesList {
    margin-bottom: 120px;

    &__count {
        font: var(--font-l);
        color: var(--color-career-text);
        margin-bottom: 30px;

        @media (max-width: $media-md) {
            margin-bottom: 10px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 80px;
    }

    &__directionList {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &__title {
        font: var(--font-xxl);
        font-weight: 500;

        & > span {
            color: var(--color-career-text);
            font-weight: 400;
        }
    }

    &__empty {
        padding-top: var(--size-between-section);
        margin-inline: auto;

        display: flex;
        flex-direction: column;
        gap: 50px;

        text-align: center;
        font: var(--font-l);

        @media (max-width: $media-sm) {
            font: var(--font-xl);

            &__emptyButton {
                width: 100%;
            }
        }
    }

    @media (max-width: $media-sm) {
        font: var(--font-xl);
    }

    &__subscribe {
        @media (max-width: $media-sm) {
            width: 100%;
        }
    }
}

.skeleton {
    &__count {
        max-width: 360px;
        height: 28px;
        margin-bottom: 30px;
    }

    &__title {
        max-width: 340px;
        height: 44px;
    }
}
